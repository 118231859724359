import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MainPage from './components/MainPage';
import AboutPage from './components/AboutPage';
import FAQPage from './components/FAQPage';
import PricingPage from './components/PricingPage';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import UserProfilePage from './components/UserProfilePage';
import AppHeader from './components/AppHeader';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import PaymentFailurePage from './components/PaymentFailurePage';
import ProductsPage from './components/ProductsPage';
import Overview from './components/Overview';
import MarketCap from './components/MarketCap';
import EtfNetInflow from './components/EtfNetInflow';
import FearGreedIndex from './components/FearGreedIndex';
import { StockComponents, StockScreener } from './components/btc-stocks';
import { callLoginApi } from './login_api';
import { getSubscriptionInfo } from './services/stripe_api';
import { setupAuthRefresh, setupSubscriptionSync } from './services/auth_service';
import { store } from './store/index';
import StockDetail from './components/btc-stocks/StockDetail';
import ProtectedRoute from './components/ProtectedRoute';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import ComingSoon from './components/btc-etf/ComingSoon';
import InsightsPage from './components/insights/InsightsPage';
import TopBitcoinStocks from './components/insights/articles/top-bitcoin-stocks';
import MvrvZValue from './components/MvrvZValue';
import ETFDetail from './components/ETF/ETFDetail';
import ETFScreener from './components/ETF/ETFScreener';
import { HelmetProvider } from 'react-helmet-async';
import LongTermPage from './components/long-term/LongTermPage';
import LongTermDetails from './components/long-term/LongTermDetails';
import LongTermScreener from './components/long-term/Screener/LongTermScreener';
import EarningsCalendar from './components/long-term/EarningsCalendar/EarningsCalendar';

// 添加错误边界组件
class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('App Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h1>Something went wrong</h1>
          <button onClick={() => window.location.reload()}>
            Refresh Page
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

window.onerror = function(message, source, lineno, colno, error) {
    console.log('Global error:', {
        message,
        source,
        lineno,
        colno,
        error
    });
    return false;
};

window.addEventListener('unhandledrejection', function(event) {
    console.log('Unhandled promise rejection:', event.reason);
});

function App() {
  const [error, setError] = useState(null);
  const isLoggedIn = useSelector(state => state.auth?.isLoggedIn || false);
  const userData = useSelector(state => state.auth?.userData || null);
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeAuth = async () => {
      if (localStorage.getItem('isLoggedIn') === 'true') {
        try {
          // 验证并刷新会话
          const userData = await callLoginApi('/load-user');
          const subscriptionData = await getSubscriptionInfo(userData.userid);
          
          dispatch({ 
            type: 'SET_USER_DATA', 
            payload: {
              ...userData,
              subscription: subscriptionData
            }
          });
          
          // 设置自动刷新
          setupAuthRefresh(store);
          setupSubscriptionSync(store);
        } catch (error) {
          dispatch({ type: 'SET_LOGGED_OUT' });
        }
      }
    };

    initializeAuth();
  }, []);

  if (error) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h1>Error Loading Application</h1>
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>
          Refresh Page
        </button>
      </div>
    );
  }

  return (
    <HelmetProvider>
      <ErrorBoundary>
        <div className="app-container">
          <AppHeader />
          <main>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route 
                path="/login" 
                element={
                  isLoggedIn ? (
                    <Navigate to="/profile" replace />
                  ) : (
                    <LoginPage />
                  )
                } 
              />
              <Route 
                path="/register" 
                element={
                  isLoggedIn ? (
                    <Navigate to="/profile" replace />
                  ) : (
                    <RegisterPage />
                  )
                } 
              />
              <Route 
                path="/profile" 
                element={
                  isLoggedIn ? (
                    <UserProfilePage />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                } 
              />
              <Route 
                path="/forgot-password" 
                element={
                  isLoggedIn ? (
                    <Navigate to="/profile" replace />
                  ) : (
                    <ForgotPasswordPage />
                  )
                } 
              />
              <Route 
                path="/reset-password" 
                element={
                  isLoggedIn ? (
                    <Navigate to="/profile" replace />
                  ) : (
                    <ResetPasswordPage />
                  )
                } 
              />
              <Route path="/payment/success" element={<PaymentSuccessPage />} />
              <Route path="/payment/failure" element={<PaymentFailurePage />} />
              <Route path="/products" element={<ProductsPage />}>
                <Route path="btc">
                  <Route index element={<Overview defaultView={true} />} />
                  <Route path="btc-overview" element={<Overview />} />
                  <Route path="market-cap" element={<MarketCap />} />
                  <Route path="etf-net-inflow" element={<EtfNetInflow />} />
                  <Route path="fear-greed-index" element={
                    <ProtectedRoute level="login">
                      <FearGreedIndex />
                    </ProtectedRoute>
                  } />
                  <Route path="mvrv-z-value" element={
                    <ProtectedRoute level="login">
                      <MvrvZValue />
                    </ProtectedRoute>
                  } />
                  <Route path="stock-screener" element={<StockScreener />} />
                  <Route path="etf-screener" element={<ETFScreener />} />
                  <Route path="etf/:ticker" element={<ETFDetail />} />
                </Route>
                <Route path="stocks">
                  <Route path="small-cap/:ticker" element={
                    <ProtectedRoute>
                      <StockDetail />
                    </ProtectedRoute>
                  } />
                  <Route path="medium-cap/:ticker" element={
                    <ProtectedRoute>
                      <StockDetail />
                    </ProtectedRoute>
                  } />
                  <Route path="large-cap/:ticker" element={
                    <ProtectedRoute>
                      <StockDetail />
                    </ProtectedRoute>
                  } />
                </Route>
                <Route path="btc-etf/*" element={<ComingSoon />} />
              </Route>
              <Route path="/insights" element={<InsightsPage />} />
              <Route path="/insights/:category" element={<InsightsPage />} />
              <Route 
                path="/insights/educational/top-8-bitcoin-stocks" 
                element={<TopBitcoinStocks />} 
              />
              <Route path="/long-term" element={<LongTermPage />}>
                <Route index element={<Overview />} />
                <Route path="overview" element={<Overview />} />
                <Route path="earnings-calendar" element={<EarningsCalendar />} />
                <Route path="stock-screener" element={<LongTermScreener />} />
                <Route path=":category/:ticker" element={<LongTermDetails />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ErrorBoundary>
    </HelmetProvider>
  );
}

export default App;