import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { getLongTermStockScreener } from '../../services/data_api';
import PageLayout from '../PageLayout';
import './LongTermPage.css';
import LongTermOverview from './Overview/LongTermOverview';
import LongTermScreener from './Screener/LongTermScreener';
import LongTermDetails from './LongTermDetails';
import EarningsCalendar from './EarningsCalendar/EarningsCalendar';

const LongTermPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState('overview');
  const [expandedItems, setExpandedItems] = useState(['long-term-stocks']);
  const [stocksByMarketCap, setStocksByMarketCap] = useState({
    'small-cap': [],
    'medium-cap': [],
    'large-cap': []
  });

  // 获取股票数据
  useEffect(() => {
    const fetchStockData = async () => {
      try {
        console.log('Fetching stock data...');
        const response = await getLongTermStockScreener();
        const stocks = response?.data || [];
        
        // 添加数据验证
        const validStocks = stocks.filter(stock => 
          stock && 
          stock.ticker && 
          stock.market_cap_rank && 
          typeof stock.market_cap_rank === 'string'
        );
        
        // 按市值分类股票
        const categorizedStocks = validStocks.reduce((acc, stock) => {
          const category = (stock.market_cap_rank || 'small').toLowerCase() + '-cap';
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push({
            ticker: stock.ticker,
            recommendation: stock.recommendation
          });
          return acc;
        }, {
          'small-cap': [],
          'medium-cap': [],
          'large-cap': []
        });

        console.log('Categorized stocks:', categorizedStocks);
        setStocksByMarketCap(categorizedStocks);
      } catch (error) {
        console.error('Error fetching stock data:', error);
        // 设置默认数据
        setStocksByMarketCap({
          'small-cap': [
            { ticker: 'MARA', recommendation: 'B' },
            { ticker: 'RIOT', recommendation: 'B' }
          ],
          'medium-cap': [
            { ticker: 'COIN', recommendation: 'A' },
            { ticker: 'MSTR', recommendation: 'B' }
          ],
          'large-cap': [
            { ticker: 'NVDA', recommendation: 'A' },
            { ticker: 'AMD', recommendation: 'B' }
          ]
        });
      }
    };

    fetchStockData();
  }, []);

  // 添加 location 变化监听
  useEffect(() => {
    const path = location.pathname;
    const matches = path.match(/\/long-term\/(.*?)(\/|$)/);
    if (matches && matches[1]) {
      setActiveCategory(matches[1]);
    }
  }, [location.pathname]);

  const handleNavigation = (itemId) => {
    setActiveCategory(itemId);
    navigate(`/long-term/${itemId}`);
  };

  const handleStockClick = (stock, category) => {
    if (!stock || !category) {
      console.error('Invalid stock or category:', { stock, category });
      return;
    }
    
    try {
      setActiveCategory(stock.toLowerCase());
      navigate(`/long-term/${category}/${stock}`);
    } catch (error) {
      console.error('Error in handleStockClick:', error);
      // 可以添加用户提示
      // toast.error('Failed to navigate to stock details');
    }
  };

  const toggleExpand = (itemId) => {
    setExpandedItems(prev => 
      prev.includes(itemId) 
        ? prev.filter(id => id !== itemId)
        : [...prev, itemId]
    );
  };

  const renderStockList = (category) => {
    if (!category) {
      console.error('Invalid category:', category);
      return null;
    }

    const stocks = stocksByMarketCap[category] || [];
    console.log(`Rendering stock list for ${category}:`, stocks);
    
    return (
      <div className="longterm-stock-list">
        {stocks.map(stock => {
          if (!stock?.ticker) {
            console.error('Invalid stock data:', stock);
            return null;
          }
          
          return (
            <button
              key={stock.ticker}
              className={`longterm-sidebar-item longterm-stock-item ${
                activeCategory === stock.ticker.toLowerCase() ? 'active' : ''
              }`}
              onClick={() => handleStockClick(stock.ticker, category)}
            >
              <span className="item-content">{stock.ticker}</span>
              <span className="longterm-feature-tag longterm-free-tag">FREE</span>
            </button>
          );
        })}
      </div>
    );
  };

  const renderContent = () => {
    switch (activeCategory) {
      case 'overview':
        return <LongTermOverview />;
      case 'stock-screener':
        return <LongTermScreener />;
      case 'earnings-calendar':
        return <EarningsCalendar />;
      default:
        // 检查是否是股票详情页面
        const allStocks = [
          ...stocksByMarketCap['small-cap'],
          ...stocksByMarketCap['medium-cap'],
          ...stocksByMarketCap['large-cap']
        ];
        
        // 从 URL 中获取 ticker
        const path = location.pathname;
        const tickerMatch = path.match(/\/long-term\/.*?\/(.+)$/);
        const urlTicker = tickerMatch ? tickerMatch[1].toUpperCase() : null;
        
        // 如果 URL 中有 ticker，直接渲染详情页
        if (urlTicker) {
          return <LongTermDetails />;
        }
        
        // 否则检查是否匹配侧边栏的股票
        const matchingStock = allStocks.find(
          s => s.ticker.toLowerCase() === activeCategory.toLowerCase()
        );
        
        if (matchingStock) {
          return <LongTermDetails />;
        }
        
        return <div>Please select a category</div>;
    }
  };

  return (
    <>
      <Helmet>
        <title>Long-term Investment - FinStock Insights</title>
      </Helmet>
      <PageLayout>
        <div className="longterm-container">
          <aside className="longterm-sidebar">
            <nav className="longterm-sidebar-nav">
              <div className="longterm-nav-item-container">
                <button
                  className={`longterm-sidebar-item ${activeCategory === 'overview' ? 'active' : ''}`}
                  onClick={() => handleNavigation('overview')}
                >
                  <span className="item-content">Overview</span>
                  <span className="longterm-feature-tag longterm-free-tag">FREE</span>
                </button>
              </div>

              <div className="longterm-nav-item-container">
                <button
                  className={`longterm-sidebar-item ${activeCategory === 'earnings-calendar' ? 'active' : ''}`}
                  onClick={() => handleNavigation('earnings-calendar')}
                >
                  <span className="item-content">Earnings Calendar</span>
                  <span className="longterm-feature-tag longterm-free-tag">FREE</span>
                </button>
              </div>

              <div className="longterm-nav-item-container">
                <button
                  className="longterm-sidebar-item group"
                  onClick={() => toggleExpand('long-term-stocks')}
                  aria-expanded={expandedItems.includes('long-term-stocks')}
                >
                  <span className="item-content">Long-term Stocks</span>
                  <span className="longterm-expand-icon">▼</span>
                </button>

                {expandedItems.includes('long-term-stocks') && (
                  <div className="longterm-sub-items">
                    <button
                      className={`longterm-sidebar-item ${activeCategory === 'stock-screener' ? 'active' : ''}`}
                      onClick={() => handleNavigation('stock-screener')}
                    >
                      <span className="item-content">Stock Screener</span>
                      <span className="longterm-feature-tag longterm-free-tag">FREE</span>
                    </button>

                    {['small-cap', 'medium-cap', 'large-cap'].map(category => (
                      <div key={category} className="longterm-market-cap-category">
                        <button
                          className={`longterm-sidebar-item ${activeCategory === category ? 'active' : ''}`}
                          onClick={() => toggleExpand(category)}
                          aria-expanded={expandedItems.includes(category)}
                        >
                          <span className="item-content">
                            {category === 'small-cap' ? 'Small Market Cap' :
                             category === 'medium-cap' ? 'Medium Market Cap' :
                             'Large Market Cap'}
                          </span>
                          <span className="longterm-feature-tag longterm-free-tag">FREE</span>
                          <span className={`longterm-expand-icon ${expandedItems.includes(category) ? 'expanded' : ''}`}>▼</span>
                        </button>
                        {expandedItems.includes(category) && renderStockList(category)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </nav>
          </aside>
          <main className="longterm-main-content">
            {renderContent()}
          </main>
        </div>
      </PageLayout>
    </>
  );
};

export default LongTermPage; 